#checkout .cart-preview {
  .cart-dropdown {
    display: none !important;
  }
}

section.checkout-step {
  padding-top: $box-padding;
  padding-bottom: $box-padding;
  border-bottom: 1px solid $border-color;
  &:first-child {
    border-top: 1px solid $border-color;
  }
  .step-title {
    text-transform: uppercase;
    cursor: pointer;
    margin-bottom: 0;
    line-height: normal;
  }
  .content {
    margin: $box-padding 0 10px;
  }
  .step-number {
    font-style: italic;
    color: $icon-color;
    font-size: 200%;
    margin-right: 2%;
  }
  .step-edit {
    font-weight: normal;
    font-size: 80%;
    cursor: pointer;
    margin-right: 2px;
    margin-left: auto;
    .material-icons {
      margin-top: -1px;
    }
  }
  .not-allowed {
    cursor: not-allowed;
    opacity: 0.5;
  }
  .content,
  .done,
  .step-edit {
    display: none;
  }
  .done {
    padding: 0;
    width: 20px;
  }
  &.-current {
    .content {
      display: block;
    }
  }
  &.-current.-reachable.-complete {
    .done,
    .step-edit {
      display: none;
    }
    .step-number {
      display: inline-block;
    }
    .content {
      display: block;
    }
  }
  &.-reachable.-complete {
    .done {
      display: inline-block;
    }
    .step-number {
      display: none;
    }
    .step-edit {
      display: block;
    }
    .content {
      display: none;
    }
  }
  &.-unreachable {
    .step-title {
      opacity: 0.5;
    }
  }
  .nav-tabs {
    justify-content: flex-start;
    .nav-separator {
      line-height: 30px;
    }
  }
  h4 {
    margin-bottom: 20px;
  }
  .default-input {
    min-width: 40%;
    &[name=address1],
    &[name=address2] {
      min-width: 60%;
    }
  }
  .radio-field {
    margin-top: 30px;
    label {
      display: inline;
    }
  }
  .checkbox-field div {
    margin-top: 60px;
  }
  .checkbox-field + .checkbox-field div {
    margin-top: 0;
  }
  .select-field div {
    background: $gray-200;
    padding: 10px 50px;
  }
  #conditions-to-approve {
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
  .payment-options {
    .payment-option {
      margin-bottom: 10px;
      label img {
        max-height: $line-height-base + rem;
      }
    }
  }
  .address-selector {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
  }
  .address-item {
    flex: 0 0 49%;
    margin-bottom: $small-space;
    &.selected {
      border: 2px solid $color_blue;
    }
    .address-footer {
      border-top: 1px solid $border-color-lighten;
      padding-top: $box-padding;
      a {
        display: inline-block;
      }
      a + a {
        margin-left: 10%;
      }
      .material-icons, .fa {
        margin-top: -2px;
      }
    }
  }
  .address-alias {
  }
  .address {
  }
  .radio-block {
    padding: 15px;
    text-align: left;
  }
  .delete-address,
  .edit-address {
  }
  .invoice-address {
    margin-top: 30px;
  }
  #delivery-addresses,
  #invoice-addresses {
  }
  .add-address {
  }
  .billing-address {
  }
  .delivery-options {
    .row {
      align-items: center;
    }
  }
  .delivery-option {
    padding: $box-padding 0;
    position: relative;
    + .delivery-option:before {
      content: '';
      position: absolute;
      top: 0;
      left: 10px;
      right: 10px;
      border-top: 1px solid $border-color-lighten;
    }
    label {
      text-align: initial;
      margin-bottom: 0;
    }
    .carrier-logo {
      text-align: center;
      margin-top: 5px;
      img {
        max-width: 100%;
      }
    }
    .custom-radio-wrapper {
      justify-content: center;
      .custom-radio {
        margin: 0;
      }
    }
  }
  .order-options {
    padding-top: $box-padding;
    border-top: 1px solid $border-color-lighten;
  }
  .carrier-delay,
  .carrier-name {
    word-break: break-word;
  }
  .carrier-delay, .carrier-price {
    font-weight: 400;
  }

  #customer-form,
  #delivery-address,
  #invoice-address,
  #login-form {
    .form-control-label {
    }
  }
  .sign-in {
    font-size: $font-size-sm;
  }
  .forgot-password {
  }
  .modal-header {
    padding: 2px 5px;
    border: none;
  }
}

.additional-information {
  margin-left: 24px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.condition-label {
}
.cancel-address {
  margin: 6px;
  display: block;
  text-decoration: underline;
}

#cart-summary-product-list {
  .media-list {
    margin: 0.5rem 0;
  }
  .media {
    align-items: center;
    + .media {
      margin-top: 0.5rem;
    }
    .media-left {
      margin-right: 0.25rem;
    }
  } 
  img {
    border: 1px solid $border-color-lighten;
    max-width: 40px;
  }
  .product-name {
  }
  .product-attribute {
    font-weight: 400;
    font-style: italic;
    font-size: 90%;
  }
  .product-quantity {
    margin-left: auto;
  }
  .product-price {
    margin-left: 5%;
  }
  .aeuc_unit_price_label {
    font-size: 90%;
  }
}

#order-summary-content {
  padding-top: 15px;
  h4.h4 {
    margin-top: 10px;
    margin-bottom: 20px;
    color: $gray-800;
  }
  h4.black {
    color: #000000;
  }
  h4.addresshead {
    margin-top: 3px;
  }
  .noshadow {
    box-shadow: none;
  }
  #order-items {
    border-right: none;
    border-left: none;
    flex: 0 0 100%;
    max-width: 100%;
    padding-right: ($grid-gutter-width / 2);
    padding-left: ($grid-gutter-width / 2);
    @include media-breakpoint-down(sm) {
      #order-items {
        padding-right: ($grid-gutter-width-small / 2);
        padding-left: ($grid-gutter-width-small / 2);
      }
    }
    h3.h3 {
      color: $gray-800;
      margin-top: 20px;
    }
    table {
      tr {
        &:first-child {
          td {
            border-top: 0;
          }
        }
      }
    }
  }
  .order-confirmation-table {
    @extend .light-box-bg;
    padding-top: 0;
  }
  .summary-selected-carrier {
    margin-bottom: 0.75rem;
    .row {
      align-items: center;
    }
    .logo-container img {
      max-width: 100%;
    }
  }
  .step-edit {
    display: block;
    text-transform: uppercase;
  }
  .step-edit:hover {
    cursor: pointer;
  }
  a {
    .step-edit {
      color: $gray-600;
    }
  }
}

#gift {
  label {
    margin-top: 10px;
  }
}

/**** ORDER CONFIRMATION *****/
#order-details {
  padding-left: 3%;
  .order-details-title {
    margin-bottom: $box-padding;
    border-top: 0 solid $border-color-lighten;
  }
  ul {
    li {
      margin-bottom: 0.5rem;
    }
  }
}
#order-items {
  border-right: 1px solid $border-color-lighten;
  padding-right: 3%;
  .order-confirmation-total {
    padding-top: $box-padding;
    border-top: 1px solid $border-color-lighten;
    table {
      width: 100%;
      tr {
        height: 30px;
        td {
          &:last-child {
            text-align: right;
          }
        }
      }
    }
  }
  .order-line {
    align-items: center;
    padding-top: $box-padding;
    padding-bottom: $box-padding;
    + .order-line {
      border-top: 1px solid $border-color-lighten;
    }
    .order-line-right {
      padding-left: 0;
    }
  }
  .image {
    max-width: 80px;
    display: block;
  }
  .details {
    .customizations {
      margin-top: 5px;
    }
  }
  .qty {
  }
}
#order-confirmation {
  #registration-form {
    width: 50%;
    margin: 0 auto 16px;
  }
}

@include media-breakpoint-down(md) {
  #order-confirmation {
    #registration-form {
      width: 100%;
    }
  }
  #order-items {
    .details {
      margin-bottom: 0.5rem;
    }
  }
}

@include media-breakpoint-down(sm) {
  #order-items {
    border-right-width: 0;
    margin-bottom: 0.5rem;
    padding-right: ($grid-gutter-width-small / 2);
  }
  #order-details {
    padding-left: ($grid-gutter-width-small / 2);
    .order-details-title {
      padding-top: 1rem;
      border-top-width: 1px;
    }
  }
  section.checkout-step .address-item {
    flex: 0 0 99%;
  }
}

@include media-breakpoint-down(xs) {
  #payment-confirmation {
    button {
      font-size: 0.875rem;
      &.btn {
        white-space: normal;
      }
    }
  }
}

.js-payment-binary {
  display: none;
  .accept-cgv {
    display: none;
  }
  &.disabled {
    opacity: 0.6;
    cursor: not-allowed;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      pointer-events: none;
    }
    .accept-cgv {
      display: block;
    }
  }
}
