.zone-product-extra-fields {
  .extra-field-type-modal {
    .extra-title {
      text-transform: none;
      text-decoration: none;
      padding: 0;
      font-weight: 700;
      border-radius: 0;
      min-height: $base-height;
      font-size: 90%;
      background: linear-gradient(to right, #ececec, #ffffff);
      img {
        max-height: $base-height;
        width: auto;
      }
      span {
        padding-left: 5px;
      }
    }
    .no-header {
      .modal-header {
        border: none;
        padding: 0px;
      }
    }
    .extra-content {
      iframe {
        border: none;
      }
    }
  }
  .product-right-extra-field {
    + .product-right-extra-field {
      margin-top: 20px;
    }
  }
}